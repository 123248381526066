/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

function SEO({ description, lang, title, author, image, url }) {
  const site = {
      title: title ?? 'Bibliotheek Verrijker',
      lang: lang ?? 'nl',
      author: author ?? 'Bibliotheek Nederland',
      description: description || 'Bibliotheek verrijker',
      url: url || 'https://bibliotheekverrijker.nl',
      image: image || '',
  }

  return (
    <Helmet
      title={site.title}
      titleTemplate={`%s | Bibliotheek Verrijker`}
      meta={[
        {
          name: `description`,
          content: site.description,
        },
        {
          property: `og:title`,
          content: site.title,
        },
        {
          property: `og:description`,
          content: site.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.url,
        },
        {
          property: `og:image`,
          content: `https:${site.image}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.author,
        },
        {
          name: `twitter:title`,
          content: site.dtitle,
        },
        {
          name: `twitter:description`,
          content: site.description,
        },
      ]}
    />
  )
}

export default SEO
